import { KeycloakOptions } from 'keycloak-angular';
import { environment } from 'environments';

export const authOptions: KeycloakOptions = { 
  config: {
      url: environment.keycloak.config.url,
      realm: environment.keycloak.config.realm,
      clientId: environment.keycloak.config.clientId,
  },
  initOptions: {
    pkceMethod: 'S256',
    checkLoginIframe: environment.keycloak.initOptions.checkLoginIframe,
    onLoad: 'login-required',
    scope: 'openid profile email offline_access',
    useNonce: true,
  },
  enableBearerInterceptor: true,
  bearerExcludedUrls: ['/assets'],
  shouldUpdateToken(request) {
      return request.headers.get('token-update') !== 'false';
  },
  loadUserProfileAtStartUp: true,
};