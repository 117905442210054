import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Warehouse } from 'app/models/Warehouse';
import { BehaviorSubject, Observable, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class WarehousesService {
  private _warehouses: BehaviorSubject<any> = new BehaviorSubject(null);

  /**
     * Constructor
     */
  constructor(private _httpClient: HttpClient) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get warehouses$(): Observable<any> {
    return this._warehouses.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
     * Get data
     */
  getWarehouses(): Observable<Array<Warehouse>> {
    return this._httpClient.get('api/warehouses').pipe(
      tap((response: any) => {
        this._warehouses.next(response);
      }),
    );
  }

  getWarehouseById(warehouseId: string): Observable<Warehouse> {
    return this._httpClient.get<Warehouse>(`api/warehouses/${warehouseId}`);
  }

  addStockMovementToWarehouse(id: Pick<Warehouse, 'id'>, productId: string, quantity: number, movementType: string, date: Date, destination: string): Observable<Warehouse> {
    try {
      const stock = {
        id,
        productId,
        date,
        quantity,
        movementType,
        destination,
      };

      return this._httpClient.patch<Warehouse>(`api/warehouses/${id}/stock-movements`, { ...stock });
    }
    catch (err) {
      console.log(err);
    }
  }
}
