export const environment = {
  production: false,
  protocol: 'https',
  domain: 'erp.aacore.com',
  hmr: true,
  keycloak: {
      config: {
        //   url: 'http://localhost:8085',
          url: 'https://oauth.aacore.com',
          realm: 'amc',
          clientId: 'amc-frontend-client',
      },
      initOptions: {
          // onLoad: 'login-required', //check-sso
          onload: 'check-sso',
          flow: 'standard',
          checkLoginIframe: false,
          silentCheckSsoRedirectUri: '/assets/silent-check-sso.html',
          pkceMethod: 'S256',
      },
  },
};