/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/dashboards/project'
  },
  {
    id: 'dashboards.sales',
    title: 'Ventas',
    type: 'collapsable',
    icon: 'heroicons_outline:currency-dollar',
    // children: [
    //   {
    //     id: 'dashboards.sales.customers',
    //     title: 'Clientes',
    //     type: 'basic',
    //     icon: 'heroicons_outline:clipboard-document-check',
    //     link: 'dashboards/customers',
    //   },
    // ]
  },
  {
    id: 'dashboards.sales.customers',
    title: 'Clientes',
    type: 'basic',
    icon: 'heroicons_outline:users',
    link: 'dashboards/customers/table'
  }
];
export const compactNavigation: FuseNavigationItem[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/dashboards/project'
  }
];
export const futuristicNavigation: FuseNavigationItem[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'basic',
    icon: 'heroicons_outline:chart-pie',
    link: '/dashboards/project'
  }
];
export const horizontalNavigation: FuseNavigationItem[] = [
  {
    id: 'home',
    title: 'Home',
    type: 'basic',
    icon: 'heroicons_outline:home',
    link: '/home'
  },
  {
    id: 'branch',
    title: 'Sucursal',
    type: 'group',
    icon: 'heroicons_outline:chart-pie',
    children: [
      {
        id: 'branch.excerpt',
        title: 'Sucursal Resumen',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/dashboards/project'
      },
      {
        id: 'branch.warehouse',
        title: 'Inventario',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/dashboards/stock'
      },
    ]
  },
  {
    id: 'contacts',
    title: 'Contactos',
    type: 'group',
    icon: 'heroicons_outline:users',
    children: [
      {
        id: 'dashboards.contacts',
        title: 'Contactos',
        type: 'basic',
        icon: 'heroicons_outline:users',
        link: 'dashboards/customers/table'
      },
      {
        id: 'dashboards.sales.addresses',
        title: 'Direcciones',
        disabled: true,
        type: 'basic',
        icon: 'heroicons_outline:users',
        link: 'dashboards/addresses/table'
      },
    ]
  },
  {
    id: 'ecommerce',
    title: 'Ecommerce',
    type: 'group',
    icon: 'heroicons_outline:shopping-cart',
    children: [
      {
        id: 'dashboards.ecommerce',
        title: 'Productos',
        type: 'collapsable',
        icon: 'heroicons_outline:shopping-cart',
        children: [
          {
            id: 'apps.ecommerce.products.table',
            title: 'Productos',
            type: 'basic',
            link: '/dashboards/ecommerce/products/table',
          },
          {
            id: 'apps.ecommerce.inventory',
            title: 'Tags',
            disabled: true,
            type: 'basic',
            link: '',
          },
          {
            id: 'apps.ecommerce.inventory',
            title: 'Atributos',
            disabled: true,
            type: 'basic',
            link: '',
          },
          {
            id: 'apps.ecommerce.inventory',
            title: 'Menus',
            disabled: true,
            type: 'basic',
            link: '',
          },
        ],
      },
    ],
  },
  {
    id: 'sales',
    title: 'Ventas',
    type: 'group',
    icon: 'heroicons_outline:currency-euro',
    children: [
      {
        id: 'sales.estimates',
        title: 'Presupuestos',
        type: 'basic',
        icon: 'heroicons_outline:document-chart-bar',
        link: '/dashboards/ecommerce/products/table',
      },
      {
        id: 'sales.orders',
        title: 'pedidos',
        type: 'basic',
        icon: 'heroicons_outline:document-plus',
        link: '/dashboards/ecommerce/products/table',
      },
      {
        id: 'sales.invoices',
        title: 'Facturas',
        type: 'basic',
        icon: 'heroicons_outline:document-check',
        link: '/dashboards/ecommerce/products/table',
      },
    ],
  },
  // {
  //   id: 'apps',
  //   title: 'Ventas',
  //   type: 'group',
  //   icon: 'heroicons_outline:currency-dollar',
  //   children: [
  //     {
  //       id: 'apps.academy',
  //       title: 'Academy',
  //       type: 'basic',
  //       icon: 'heroicons_outline:academic-cap',
  //       link: '/apps/academy',
  //     },
  //     {
  //       id: 'apps.chat',
  //       title: 'Chat',
  //       type: 'basic',
  //       icon: 'heroicons_outline:chat-bubble-bottom-center-text',
  //       link: '/apps/chat',
  //     },
  //     {
  //       id: 'apps.contacts',
  //       title: 'Contacts',
  //       type: 'basic',
  //       icon: 'heroicons_outline:user-group',
  //       link: '/apps/contacts',
  //     },
  //     {
  //       id: 'dashboards.ecommerce',
  //       title: 'ECommerce',
  //       type: 'collapsable',
  //       icon: 'heroicons_outline:shopping-cart',
  //       children: [
  //         {
  //           id: 'apps.ecommerce.inventory',
  //           title: 'Inventory',
  //           type: 'basic',
  //           link: '/dashboards/ecommerce/inventory',
  //         },
  //       ],
  //     },
  //     {
  //       id: 'apps.file-manager',
  //       title: 'File Manager',
  //       type: 'basic',
  //       icon: 'heroicons_outline:cloud',
  //       link: '/apps/file-manager',
  //     },
  //     {
  //       id: 'apps.help-center',
  //       title: 'Help Center',
  //       type: 'collapsable',
  //       icon: 'heroicons_outline:information-circle',
  //       link: '/apps/help-center',
  //       children: [
  //         {
  //           id: 'apps.help-center.home',
  //           title: 'Home',
  //           type: 'basic',
  //           link: '/apps/help-center',
  //           exactMatch: true,
  //         },
  //         {
  //           id: 'apps.help-center.faqs',
  //           title: 'FAQs',
  //           type: 'basic',
  //           link: '/apps/help-center/faqs',
  //         },
  //         {
  //           id: 'apps.help-center.guides',
  //           title: 'Guides',
  //           type: 'basic',
  //           link: '/apps/help-center/guides',
  //         },
  //         {
  //           id: 'apps.help-center.support',
  //           title: 'Support',
  //           type: 'basic',
  //           link: '/apps/help-center/support',
  //         },
  //       ],
  //     },
  //     {
  //       id: 'apps.mailbox',
  //       title: 'Mailbox',
  //       type: 'basic',
  //       icon: 'heroicons_outline:envelope',
  //       link: '/apps/mailbox',
  //       badge: {
  //         title: '27',
  //         classes: 'px-2 bg-pink-600 text-white rounded-full',
  //       },
  //     },
  //     {
  //       id: 'apps.notes',
  //       title: 'Notes',
  //       type: 'basic',
  //       icon: 'heroicons_outline:pencil-square',
  //       link: '/apps/notes',
  //     },
  //     {
  //       id: 'apps.scrumboard',
  //       title: 'Scrumboard',
  //       type: 'basic',
  //       icon: 'heroicons_outline:view-columns',
  //       link: '/apps/scrumboard',
  //     },
  //     {
  //       id: 'apps.tasks',
  //       title: 'Tasks',
  //       type: 'basic',
  //       icon: 'heroicons_outline:check-circle',
  //       link: '/apps/tasks',
  //     },
  //   ],
  // },
];
