<mat-form-field
  subscriptSizing="dynamic"
  appearance="outline"
  *ngIf="branches.length > 1"
  class="branches-selector"
>
  <mat-label>Sucursal</mat-label>

  <mat-select
    #branchSelect
    [formControl]="branchesFormControl"
    [ngModel]="currentBranchId"
    (ngModelChange)="onSelectionChange($event)"
  >
    <mat-option
      *ngFor="let branch of branches"
      [value]="branch.id"
    >
      <div style="display: inline-flex; font-size: 14px">
        <svg
          *ngIf="!branch.image"
          class="logo-image"
          [data-jdenticon-value]="branch.name"
        ></svg>
        <div class="mt-1">{{ branch.name }}</div>
      </div>
    </mat-option>
  </mat-select>
</mat-form-field>