import { UserService } from 'app/core/user/user.service';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '../auth.service';
import { Observable, filter, of, switchMap, tap } from 'rxjs';

export const UserIsAdminGuard: CanActivateFn | CanActivateChildFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
  ): Observable<boolean> => {
    const userService = inject(UserService);
    const authService = inject(AuthService);
    const router = inject(Router);
    
    console.log("UserIsAdminGuard: Checking if user is admin");
    return authService.check().pipe(
      tap((authenticated) => {
        if (!authenticated) {
          // const redirectUrl =  window.location.origin + `/sign-in?redirectUrl${state.url}`;
          const redirectUrl = window.location.origin + state.url;
        
          authService.signIn(redirectUrl); 
        } 
      }),
      switchMap(() => userService.loggedInUser$),
      filter((loggedInUser) => !!loggedInUser),
      switchMap(async (loggedInUser) => {
        console.log(loggedInUser)
        if(!loggedInUser.role.includes('admin')){
          router.navigate(['/error/401']);
        }
        return loggedInUser.role.includes('admin');
      }),
  );
}


