import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors } from '@angular/common/http';
import { APP_INITIALIZER, EnvironmentProviders, Provider } from '@angular/core';
import { authInterceptor } from 'app/core/auth/auth.interceptor';
import { AuthService } from 'app/core/auth/auth.service';
import { KeycloakAngularModule, KeycloakBearerInterceptor, KeycloakService } from 'keycloak-angular';
import { authInitializerFactory } from './auth.initializer-factory';

export const provideAuth = (): Array<Provider | EnvironmentProviders> => {
  return [
    KeycloakAngularModule,
    KeycloakService,
    KeycloakBearerInterceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: KeycloakBearerInterceptor,
      multi: true,
      deps: [ KeycloakService ],
    },
    provideHttpClient(withInterceptors([ authInterceptor ])),
    {
      provide: APP_INITIALIZER,
      useFactory: authInitializerFactory,
      multi: true,
      deps: [ AuthService ],
    },
  ];
};
