import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { environment } from 'environments';
import { KeycloakService } from 'keycloak-angular';
import { Observable, ReplaySubject, catchError, from, of, switchMap, throwError } from 'rxjs';
import { authOptions } from './auth.config';

export type UserInfo = {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  roles: Array<string>;
  organizations: Array<string>;
};

@Injectable({ providedIn: 'root' })
export class AuthService {
  private _authenticated = false;
  public userTokenSubject$: ReplaySubject<string> = new ReplaySubject(1);

  /**
     * Constructor
     */
  constructor(
    private _httpClient: HttpClient,
    private _oauthService: KeycloakService,
  ) {

  }

  public async configure(): Promise<void> {
    try {
      return this._oauthService
        .init(authOptions)
        .then(async () => {
          if (await this._oauthService.isLoggedIn()) {
            const keycloak = await this._oauthService.getKeycloakInstance();

            this.userTokenSubject$.next(keycloak.token);
          }
        })
        .catch(error => {
          console.error("Error configuring keycloak", error);
        });
    } catch (error) {
      console.error("Error configuring keycloak", error);
    }
  }


  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
     * Setter & getter for access token
     */
  set accessToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
     * Forgot password
     *
     * @param email
     */
  forgotPassword(email: string): Observable<any> {
    return this._httpClient.post('api/auth/forgot-password', email);
  }

  /**
     * Reset password
     *
     * @param password
     */
  resetPassword(password: string): Observable<any> {
    return this._httpClient.post('api/auth/reset-password', password);
  }

  /**
     * Sign in
     *
     * @param credentials
     */
  async signIn(redirectUrl: string): Promise<void> {
    return await this._oauthService.login({
      redirectUri: redirectUrl,
    });

    // // Throw error, if the user is already logged in
    // if (this._authenticated) {
    //   return throwError('User is already logged in.');
    // }

    // return this._httpClient.post('api/auth/sign-in', credentials).pipe(
    //   switchMap((response: any) => {
    //     // Store the access token in the local storage
    //     this.accessToken = response.accessToken;

    //     // Set the authenticated flag to true
    //     this._authenticated = true;

    //     // Store the user on the user service
    //     response.user = {
    //       email: "Asdasd",
    //       id: "1",
    //       firstName: "Asdasd",
    //       lastName: "Asdasd",
    //     }
    //     this._userService.user = response.user;

    //     // Return a new observable with the response
    //     return of(response);
    //   }),
    // );
  }

  /**
     * Sign in using the access token
     */
  signInUsingToken(): Observable<any> {
    // Sign in using the token
    return this._httpClient.post('api/auth/sign-in-with-token', {
      accessToken: this.accessToken,
    }).pipe(
      catchError(() =>

        // Return false
        of(false)),
      switchMap((response: any) => {
        // Replace the access token with the new one if it's available on
        // the response object.
        //
        // This is an added optional step for better security. Once you sign
        // in using the token, you should generate a new one on the server
        // side and attach it to the response object. Then the following
        // piece of code can replace the token with the refreshed one.
        if (response.accessToken) {
          this.accessToken = response.accessToken;
        }

        // Set the authenticated flag to true
        this._authenticated = true;

        // Store the user on the user service
        console.log("asdasdasd");
        response.user = {
          email: "Asdasd",
          id: "1",
          firstName: "Asdasd",
          lastName: "Asdasd",
        }
        // this._userService.user = response.user;

        // Return true
        return of(true);
      }),
    );
  }

  /**
     * Sign out
     */
  signOut(): Observable<any> {
    this._oauthService.logout();
    // Remove the access token from the local storage
    localStorage.removeItem('accessToken');

    // Set the authenticated flag to false
    this._authenticated = false;

    // Return the observable
    return of(true);
  }

  /**
     * Sign up
     *
     * @param user
     */
  signUp(user: { name: string; email: string; password: string; company: string }): Observable<any> {
    return this._httpClient.post('api/auth/sign-up', user);
  }

  /**
     * Unlock session
     *
     * @param credentials
     */
  unlockSession(credentials: { email: string; password: string }): Observable<any> {
    return this._httpClient.post('api/auth/unlock-session', credentials);
  }

  /**
     * Check the authentication status
     */
  check(): Observable<boolean> {
    return of(this._oauthService.isLoggedIn());
  }
}
