import { JsonPipe, NgFor, NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormField, MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { AvailableLangs, TranslocoService } from '@ngneat/transloco';
import { Branch } from 'app/models/Branch';
import { BranchesService } from 'app/services/branches/branches.service';
import { Subscription, take } from 'rxjs';
import { NgxJdenticonModule } from "ngx-jdenticon";

@Component({
  selector: 'branch-selector',
  templateUrl: './branchSelector.component.html',
  encapsulation: ViewEncapsulation.None,
  imports: [ JsonPipe, NgIf, NgFor, NgForOf, MatFormFieldModule, MatInputModule, MatSelectModule, ReactiveFormsModule, NgxJdenticonModule ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class BranchSelector {
  @ViewChild('branchSelect') branchSelect!: MatSelect;
  
  public branches: Array<Branch> = [];
  public branchesFormControl = new FormControl('');
  public currentBranchId: Branch['id'] | null = null;
  private subscriptions: Subscription = new Subscription();
  
  /**
     * Constructor
     */
  constructor(
    private _branchesService: BranchesService,
  ) {
    this.subscriptions.add(
      this._branchesService.branches$().subscribe(branches => {
        this.branches = branches;
      })
    );

    this.subscriptions.add(
      this._branchesService.currentBranch$().subscribe(currentBranch => {
        if (currentBranch) {
          this.currentBranchId = currentBranch.id;
        }
      })
    );
    
  }

  onSelectionChange(BranchId: string) {
    const relatedBranch = this._branchesService.getBranchById(BranchId);

    if (relatedBranch) {
      this._branchesService.setCurrentBranch(relatedBranch);
    }
    // this.selectionChanged.emit(selectedBranchId);
    // this.currentBranchName = selectedBranchId;
    // if (this.currentBranchName) {
    //   this._dashboardsService.changeUserContext(this.currentBranchName);
    // }
  }

  /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

}
