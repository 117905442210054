import { BehaviorSubject, Observable, Subject, firstValueFrom, from, lastValueFrom, skip } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Branch } from 'app/models/Branch';
import { environment } from 'environments';
import { UserSettingsService } from 'app/services/user-settings/user-settings.service';
import { Warehouse } from 'app/models/Warehouse';
import { WarehousesService } from '../warehouses/warehouses.service';

@Injectable({
  providedIn: 'root',
})
export class BranchesService {
  private _apiUrl = `${environment.protocol}://${environment.domain}/api`;
  private branches: Array<Branch> = [];
  private _branches$: BehaviorSubject<Array<Branch>> = new BehaviorSubject([]);
  private _currentBranch$: BehaviorSubject<Pick<Branch, 'id' | 'name' | 'warehouses'> | null> = new BehaviorSubject(null);

  constructor(
    private _http: HttpClient,
    private _userSettingsService: UserSettingsService,
    private _wareHousesService: WarehousesService,
  ) {
    this._getBranchesFromBackend().subscribe((branches) => {
      this.branches = branches;
      this._branches$.next(branches);

      if (branches.length) {
        const currentBranchFromUserSettings = this._userSettingsService.getCurrentBranch();
        if (currentBranchFromUserSettings) {
          this._currentBranch$.next(currentBranchFromUserSettings);
        }
        else {
          this._currentBranch$.next({
            id: branches[0].id,
            name: branches[0].name,
            warehouses: branches[0].warehouses,
          });
        }
      }
    });
    this._currentBranch$
      .pipe(
        skip(1),
      )
      .subscribe({
        next: (currentBranch) => {
          if (currentBranch) {
            this._userSettingsService.persistCurrentBranch(currentBranch);
          }
        },
      });
  }
  private branchById(id: string): Branch {
    const found = this.branches.find((o) => o.id === id);
    if (!found) {
      throw new Error('Branch not found');
    }
    return found;
  }
  private _getBranchesFromBackend(): Observable<Array<Branch>> {
    return this._http.get<Array<Branch>>(this._apiUrl + '/branches');
  }
  public branches$(): Observable<Array<Branch>> {
    return this._branches$;
  }
  // eslint-disable-next-line max-len
  public patchBranches(branch: Pick<Branch, 'id'> & Partial<Pick<Branch, 'name'>>): Observable<any> {
    return this._http.patch<any>(
      this._apiUrl + '/branches/' + branch.id,
      branch,
    );
  }
  public deleteBranches(branch: any): Observable<any> {
    return this._http.delete<any>(this._apiUrl + '/branches/' + branch.id, {
      body: branch,
    });
  }
  public getBranches(): Observable<Array<Branch>> {
    return this._http.get<Array<Branch>>(this._apiUrl + '/branches');
  }
  public getBranchByUuid(
    branch: Branch,
  ): Observable<Branch> {
    return this._http.get<Branch>(
      this._apiUrl + '/branches/' + branch.id,
    );
  }
  public getBranchById(id: string): Branch {
    return this.branchById(id);
  }
  public setCurrentBranch(branch: Pick<Branch, 'id' | 'name' | 'warehouses'>) {
    this._currentBranch$.next(branch);
  }
  public async getCurrentBranch(): Promise<Pick<Branch, 'id' | 'name' | 'warehouses'> | null> {
    return await lastValueFrom(this._currentBranch$);
  }
  public currentBranch$(): Observable<Pick<Branch, 'id' | 'name' | 'warehouses'> | null> {
    return this._currentBranch$;
  }
  public getBranchWarehouses(): Array<Warehouse> {
    const warehousesIds = this._currentBranch$.value?.warehouses || [];
    return warehousesIds.map((warehouseId) => firstValueFrom(this._wareHousesService.getWarehouseById(warehouseId)));
    // return this._currentBranch$.value?.warehouses || [];
    // return this._wareHousesService.getWarehousesByBranchId(this._currentBranch$.value?.id || '');
  }
}
