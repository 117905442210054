import { Injectable } from '@angular/core';
import { Branch } from 'app/models/Branch';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsService {
  public persistCurrentBranch(organization: Pick<Branch, 'id' | 'name' | 'warehouses'>): void {
    localStorage.setItem('currentBranch', JSON.stringify(organization));
  }

  public getCurrentBranch(): Pick<Branch, 'id' | 'name' | 'warehouses'> | null {
    const persistedSetting = localStorage.getItem('currentBranch');

    if (persistedSetting) {
      try {
        const currentBranch = JSON.parse(persistedSetting);

        if (!currentBranch.id || !currentBranch.name) {
          return null;
        }

        return currentBranch as Pick<Branch, 'id' | 'name' | 'warehouses'>;
      }
      catch (err) {
        return null;
      }
    }

    return null;
  }

  public getSelectedLang() {
    return localStorage.getItem('lang');
  }

  public setSelectedLang(lang: string) {
    return localStorage.setItem('lang', lang);
  }

}
