import { HttpClient, HttpParams } from '@angular/common/http';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { Observable, ReplaySubject, map, take, tap } from 'rxjs';

import { AuthService } from '../auth/auth.service';
import { Injectable } from '@angular/core';
import { User } from 'app/core/user/user.types';
import { jwtDecode } from "jwt-decode";

type UserDecodedToken = {
  exp: number;
  iat: number;
  resource_access: {
    'amc-frontend': {
      roles: Array<string>;
    };
  };
  amc: {
    organizations: Array<string>;
    userId: string;
  };
  name: string;
  given_name: string;
  family_name: string;
  email: string;
};

@Injectable({ providedIn: 'root' })
export class UserService {
  private _user: ReplaySubject<User> = new ReplaySubject<User>(1);
  public loggedInUser$: ReplaySubject<User> = new ReplaySubject(1);

  /**
     * Constructor
     */
  constructor(
    private _httpClient: HttpClient,
    private _oauthService: KeycloakService,
    private _authService: AuthService,
  ) {
    this._oauthService.keycloakEvents$.subscribe({
      next: (event) => {
        console.log('evento keycloak:', event);

        if (event.type === KeycloakEventType.OnTokenExpired) {
          console.log('actualizando el token...');
          this._oauthService.updateToken();
        }
        else if(event.type === KeycloakEventType.OnAuthLogout){
          this.loggedInUser$.next(null);
          this._oauthService.logout();
        }
        else if (event.type === KeycloakEventType.OnAuthRefreshSuccess) {
          this._oauthService.getToken().then((token) => {
            console.log('token actualizado...');
            this.processToken(token);
          });
        }
      },
      error: console.error,
    });

    this._authService.userTokenSubject$.subscribe({
      next: (token) => {
        console.log('token actualizado...');
        this.processToken(token);
      },
      error: console.error,
    });

  }

  private async processToken(token: string): Promise<void> {
    const decodedToken = jwtDecode<UserDecodedToken>(token);
    this.getUserByUsername(decodedToken.email).subscribe({
      next: (users) => {
        console.log('next to loggedInUser in processToken', users);
        this.loggedInUser$.next(users[0]);
      },
      error: console.error,
    });
  }

  /**
     * Setter & getter for user
     *
     * @param value
     */
  set user(value: User) {
    // Store the value
    this._user.next(value);
  }

  get user$(): Observable<User> {
    return this._user.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
     * Get the current logged in user data
     */
  get(): Observable<User> {

    return this._httpClient.get<User>('api/user').pipe(
      tap((user) => {
        this._user.next(user);
      }),
    );
  }

  getUserById(id: string): Observable<User> {
    const params = new HttpParams().set('id', id);
    const foundUser = this._httpClient.get<User>(`api/users/${id}`, { params });
    console.log('get user by id', foundUser);
    return foundUser.pipe(
      take(1),
      tap((user) => {
        console.log('user on service', user);
        this._user.next(user);
        return user;
      })
    );
  }

  getUserByUsername(username: string): Observable<User>{
    const filters = [
        {
          "field": "email",
          "operator": "=",
          "value": username
        }
      ];
      
    const params = new HttpParams().set('filters', JSON.stringify(filters));
    return this._httpClient.get<User>(`api/users/filters`, { params: params });

    // return foundUser.pipe(
    //   take(1),
    //   tap((users) => {
    //     console.log('next a loggedInUser', users[0]);
    //     this.loggedInUser$.next(users[0]);
    //     return users[0];
    //   })
    // );
  }

  /**
     * Update the user
     *
     * @param user
     */
  update(user: User): Observable<any> {
    return this._httpClient.patch<User>('api/common/user', { user }).pipe(
      map((response) => {
        this._user.next(response);
      }),
    );
  }
}
